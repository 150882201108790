/** @format */

import { doc, setDoc } from "firebase/firestore";
import { db } from "firemade";
import { nanoid } from "nanoid";
async function save({
  id = nanoid(),
  type = "",
  collections = [],
  attachedTo = [],
  tags = [],
  meta = {},
  url,
  file,
  variations,
  directory,
  shared = false,
  userId,
}) {
  return await new Promise(async (resolve, reject) => {
    try {
      let timestamp = Date.now();
      let payload = {
        id: id,
        userId: userId,
        type: type,
        meta: meta,
        collections: collections,
        attachedTo: attachedTo,
        variations: variations,
        tags: tags,
        shared: shared,
        url: url,
        directory: directory,
        filename: file,

        timestamp: {
          created: timestamp,
          updated: timestamp,
        },
      };

      // Update the state with the new collections
      this.setState((prevState) => ({
        queries: {
          ...prevState.queries,
          ...collections.reduce((acc, collection) => ({ ...acc, [collection]: [] }), {}),
        },
      }));

      await setDoc(doc(db, "media", id), payload).then((doc) => {
        resolve(payload);
      });
    } catch (error) {
      this.onError(error);
      return reject(error, "~71");
    }
  });
}

export { save };
