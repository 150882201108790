/** @format */
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Component as ReactComponent, createRef } from "react";

import { withCms } from "cms";
import DynamicIcon from "dynamicicon";
import { ComponentError } from "errors";
import isSSR from "isssr";
import { withLocales } from "locales";
import modifier from "modifier";
import { Badge, Panel } from "notifications";
import { withPerforming } from "performing";
import { withRouter } from "router";
import { Tip, withTour } from "tour";
import { withUI } from "ui";
import { Avatar as UserAvatar, withUser } from "user";

const StyledBox = styled(Box)(() => ({
  marginLeft: "0px",
}));

class Right extends ReactComponent {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.modifiers = modifier();
  }

  signOut = () => {
    const { ui, user, t, performing, router, cms } = this.props;
    const { navigate } = router;

    // Open the confirm dialog
    ui.confirm.open({
      title: t("signOut"),
      text: t("signOutConfirmation"),
      dismiss: {
        onClick: () => {
          ui.confirm.close();
        },
        text: "Cancel",
      },
      confirm: {
        onClick: () => {
          if (cms.section("app")) performing.set.working(true, "~71");
          user.authentication.signOut(true).then(() => {
            if (cms.section("app")) {
              navigate("/");
              if (!isSSR) document.location.href = "/";
            }
          });
        },
        text: "Continue",
      },
    });
  };

  // This looks old
  componentDidMount() {
    this.props.ui.add.dialogs(["bugs"]);
  }

  render() {
    const { ui, t, locales, tour, disabled = false, user, cms, router } = this.props;

    const { navigate } = router;
    const { url } = locales;

    return (
      <StyledBox data-component="account-styled-box" data-testid="right-menu">
        <ComponentError>
          <Tip title={t("tourAccount")} name="account" tour={tour.name} arrow placement="top-end">
            <Button
              variant="tab"
              component="button"
              onClick={() => ui.menu.toggle("bar.account")}
              ref={this.ref}
              disabled={disabled}
              sx={{ borderRadius: "3px", padding: 0 }}
              role="button"
            >
              <Badge>
                <UserAvatar {...this.props} name={true} />
              </Badge>
            </Button>
          </Tip>
          {this.ref.current && (
            <Popper
              open={ui.opened("bar.account")}
              anchorEl={this.ref.current}
              placement="bottom-start"
              modifiers={this.modifiers}
              style={{ zIndex: 2000 }}
              transition
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={() => ui.menu.close("bar.account")}>
                  <Grow {...TransitionProps} style={{ zIndex: 2000 }}>
                    <Paper style={{ zIndex: 2000 }}>
                      <Panel>
                        {user.authenticated ? (
                          <MenuList style={{ paddingTop: 0, paddingBottom: 0 }}>
                            {user.meta.get("beta") && false && (
                              <ListItemButton
                                disabled={disabled}
                                onClick={() => {
                                  ui.close();
                                  navigate(url(`/people/${user.id}`));
                                }}
                              >
                                <ListItemIcon>
                                  <DynamicIcon icon="fa-user" />
                                </ListItemIcon>
                                <ListItemText primary={t("profile")} />
                              </ListItemButton>
                            )}
                            <ListItemButton onClick={() => ui.dialog.open("settings")} disabled={disabled}>
                              <ListItemIcon>
                                <DynamicIcon icon="fa-cog" />
                              </ListItemIcon>
                              <ListItemText primary={t("accountSettings")} />
                            </ListItemButton>
                            <Divider />
                            {cms.section("app") && (
                              <ListItemButton disabled={disabled} onClick={() => ui.dialog.open("billing/dialog")}>
                                <ListItemIcon>
                                  <DynamicIcon icon="fa-receipt" />
                                </ListItemIcon>
                                <ListItemText primary={t("billing")} />
                              </ListItemButton>
                            )}
                            <ListItemButton disabled={disabled} onClick={() => ui.dialog.open("workspace/support")}>
                              <ListItemIcon>
                                <DynamicIcon icon="fa-ticket" />
                              </ListItemIcon>
                              <ListItemText primary={t("support")} />
                            </ListItemButton>
                            <Divider />
                            {/* <ListItemButton disabled={true} onClick={() => ui.dialog.open("profile")}>
                            <ListItemIcon>
                              <DynamicIcon icon={"fa-id-card-clip"} />
                            </ListItemIcon>
                            <ListItemText primary={t("accountProfile")} />
                          </ListItemButton>
                          <Divider /> */}
                            <ListItemButton onClick={() => this.signOut()} disabled={disabled}>
                              <ListItemIcon>
                                <DynamicIcon icon="fa-sign-out-alt" />
                              </ListItemIcon>
                              <ListItemText primary={t("accountSignOut")} />
                            </ListItemButton>
                          </MenuList>
                        ) : (
                          <MenuList style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <ListItemButton onClick={() => ui.dialog.open("signin")}>
                              <ListItemIcon>
                                <DynamicIcon icon={"fa-sign-in-alt"} />
                              </ListItemIcon>
                              <ListItemText primary={t("signIn")} />
                            </ListItemButton>
                          </MenuList>
                        )}
                      </Panel>
                    </Paper>
                  </Grow>
                </ClickAwayListener>
              )}
            </Popper>
          )}
        </ComponentError>
      </StyledBox>
    );
  }
}

export default withLocales(withUser(withTour(withUI(withPerforming(withRouter(withCms(Right)))))));
