/** @format */
// This is incomplete - it's a quick and dirty way to get the SmartButtons working in the new system.
export default function SmartButtons(ButtonConfig, props, from = "~3") {
  const buttonResponse = ButtonConfig(props);
  // This is added only for futureproofing
  const middleResponse = {
    ...buttonResponse,
    __global: {
      timestamp: null,
    },
  };
  return middleResponse;
}

export function Disabled(props, slug, buttonProps) {
  return !Enabled(props, slug, buttonProps);
}

export function Enabled(props, slug, buttonProps = null) {
  if (!props || !slug || !props[slug] || !props[slug]) return false;
  if (typeof props[slug].enabled == "boolean") return props[slug].enabled;
  if (typeof props[slug].enabled == "function") {
    try {
      let response = props[slug].enabled(buttonProps);
      return typeof response == "boolean" ? response : false;
    } catch (_) {}
  }
  return false;
}

export function Visible(props, slug, buttonProps) {
  if (!props || !slug || !props[slug] || !props[slug]) return false;
  if (typeof props[slug].visible == "boolean") return props[slug].visible;
  if (typeof props[slug].visible == "function") {
    try {
      let response = props[slug].visible(buttonProps);
      return typeof response == "boolean" ? response : false;
    } catch (_) {}
  }
  return false;
}
