/** @format */
import { query as fQuery, getDocs, limit, startAfter, where } from "firebase/firestore";
import config from "../../config";
/* Query the db */
async function query(props) {
  const {
    name,
    field = null,
    eq = null,
    value = null,
    userId = null,
    attachedTo = null,
    collections = null,
    tags = null,
    limit: limited = 500, // larger limit
  } = props;
  return new Promise(async (resolve, reject) => {
    try {
      if (!name) {
        return reject("You must supply a name for this query");
      }
      if (!this.connection) return [];

      // Start with the base connection for your query
      let queryInstance = this.connection;

      // If there's a userId, add a where clause for it
      if (userId) {
        queryInstance = fQuery(queryInstance, where("userId", "==", userId));
      }

      // If there's a field, eq, and value, add a where clause for them
      if (field && eq && value !== null) {
        queryInstance = fQuery(queryInstance, where(field, eq, value));
      } else {
        // If there's no field, eq, and value, prioritize array matches in this order: attachedTo, collections, tags
        if (attachedTo) {
          queryInstance = fQuery(queryInstance, where("attachedTo", "array-contains-any", attachedTo));
        } else if (collections) {
          queryInstance = fQuery(queryInstance, where("collections", "array-contains-any", collections));
        } else if (tags) {
          queryInstance = fQuery(queryInstance, where("tags", "array-contains-any", tags));
        }
      }

      if (props.lastVisible) {
        queryInstance = fQuery(queryInstance, startAfter(props.lastVisible));
      }

      // Limit the results
      queryInstance = fQuery(queryInstance, limit(limited));

      // Execute the query and get the documents
      let querySnapshot = await getDocs(queryInstance);
      let docs = querySnapshot.docs.map((doc) => doc.data()); // map each doc to its data

      if (config.cdn.enabled) this.replaceUrlsInObject(docs);

      // Filter the results based on attachedTo, collections, and tags
      if (attachedTo && !(field && eq && value !== null)) {
        docs = docs.filter((doc) => doc.attachedTo.some((id) => attachedTo.includes(id)));
      }

      if (collections && !(field && eq && value !== null)) {
        docs = docs.filter((doc) => doc.collections.some((collection) => collections.includes(collection)));
      }

      if (tags && !(field && eq && value !== null)) {
        docs = docs.filter((doc) => doc.tags.some((tag) => tags.includes(tag)));
      }

      this.setState(
        (prevState) => {
          // Get the existing documents
          let existingDocs = prevState.queries[name] || [];

          // Append the new documents
          let newDocs = existingDocs.concat(docs);

          // At the end of your function before the resolve(true), update lastVisible and queryStates
          this.lastVisible[name] = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.queryStates[name] = props;

          return {
            queried: true,
            queries: { ...prevState.queries, [name]: newDocs },
          };
        },
        () => {
          resolve(this.state.queries[name]);
        }
      );
    } catch (error) {
      this.onError(error);
      reject(error, "~136");
    }
  });
}

export { query };
