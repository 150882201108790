/** @format */
import { ComponentError } from "errors";
import { Suspense } from "react";

export default function MenuComponent(props) {
  const { menus, position, desk } = props;
  if (menus[position] && menus[position][desk]) {
    let Component = menus[position][desk];
    if (!Component) return <></>;
    return (
      <ComponentError>
        <Suspense>
          <Component {...props} />
        </Suspense>
      </ComponentError>
    );
  } else {
    return <></>;
  }
}
