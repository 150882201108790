/** @format */
import { Box, Button, Card, Divider, IconButton, Stack, Tooltip } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { TinyError } from "errors";
import key from "key";
import { useState } from "react";

import { useWorkspace } from "@Workspace/Context";
import { useCms } from "cms";
import parse from "html-react-parser";
import { useLocales } from "locales";
import LinesEllipsis from "react-lines-ellipsis";
import { Functional } from "unit";
import TimeRange from "./libraries/TimeRange";

const unit = Functional.unit("Step");

export default function Step(props) {
  unit.report({
    method: "Step",
    test: "",
  });
  const { actions } = useWorkspace().checklist.step;
  const { step } = props;
  const { t } = useLocales();
  const { pages } = useCms();

  let [full, setFull] = useState(false);

  if (step == undefined) return <></>;

  let kb = step.kb
    ? pages.filter((page) => page.section == "kb").filter((page) => page.slug.replace("kb/", "") == step.kb)[0]
    : null;

  const blocks = ({ blocks }) => {
    let text = "";
    if (blocks.length > 0) {
      text = blocks[0].text.split("</p>")[0].replace("<p>", "");
    }
    return parse(text);
  };

  const more = ({ blocks }) => {
    if (blocks.length < 2) return <></>;
    return <></>;
    // return (
    //   <Link disabled={true} sx={{ fontWeight: 500 }}>
    //     {t("tellMeMore")}
    //   </Link>
    // );
  };

  const takes = (time) => {
    const { t } = useLocales();
    let range = TimeRange(time);

    if (range == "") return <>{t("takesWhatItTakes")}.</>;

    return (
      <>
        {range.includes("&") ? t("takesBetween") : t("takesAbout")} {range}.
      </>
    );
  };

  if (!step.visible) return <></>;

  return (
    <TinyError>
      <Card
        sx={{ backgroundColor: "transparent", margin: "10px 0 0 20px", opacity: step.enabled ? 1 : 0.4 }}
        {...key("workspace", "features", "menu", "item", step)}
        elevation={0}
      >
        <h5 style={{ margin: 0 }}>{t(step.title)}</h5>
        <TinyError>
          {kb ? (
            <Box sx={{ fontSize: "14px", margin: "10px 20px 20px 0px" }}>
              {!full ? (
                <Box sx={{ position: "relative" }}>
                  <LinesEllipsis text={blocks(kb)} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
                  <Box sx={{ position: "absolute", right: "-10px", bottom: "-3px" }}>
                    <Tooltip title={t("more")}>
                      <IconButton sx={{ fontSize: "12px" }} onClick={() => setFull(true)}>
                        <DynamicIcon icon={"fa-graduation-cap"} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ "& img": { maxWidth: "100%" } }}>
                  {blocks(kb)} {more(kb)}
                </Box>
              )}
              <Box sx={{ margin: "20px 0" }}>
                <Divider />
              </Box>
            </Box>
          ) : (
            <Box sx={{ padding: "30px 0px" }}>
              {/* Add these back in */}
              {/* <Alert severity="info" sx={{ width: "90%" }}>
                {t("missingKBAlert")} <strong>{step.kb}</strong>.
              </Alert> */}
            </Box>
          )}
        </TinyError>
        <Stack direction="row" sx={{ padding: "0px 20px 10px 0", opacity: 0.5 }} justifyContent="space-between">
          <Box sx={{ textAlign: "left" }}>
            <TinyError>
              <Box sx={{ margin: "10px 0", fontSize: "12px" }}>
                <DynamicIcon icon={"fa-clock"} />
                <Box component="span" sx={{ marginLeft: "10px", "& img": { maxWidth: "100%" } }}>
                  {takes(step.time)}
                </Box>
              </Box>
            </TinyError>
          </Box>
          {step.actions && Array.isArray(step.actions) && step.actions.length > 0 && (
            <Button
              onClick={() => actions(step.actions)}
              variant="contained"
              color={step.complete ? "success" : "warning"}
              disabled={step.enabled.enabled == false}
            >
              <Box component="span">
                {step.complete ? <DynamicIcon icon={"fa-check"} /> : <DynamicIcon icon={"fa-circle-exclamation"} />}
              </Box>
            </Button>
          )}
        </Stack>
        {step.note && (
          <Box
            style={{
              textAlign: "center",
              padding: "20px",
              fontSize: "10px",
              fontStyle: "italic",
              color: "rgba(12,12,12,.5)",
            }}
          >
            {t(step.note)}
          </Box>
        )}
      </Card>
    </TinyError>
  );
}
