/** @format */
import Config from "@Workspace/config";
import { Functional } from "unit";

const unit = Functional.unit("workspace/checklist");

export default async function setupChecklist(from = "~7") {
  let testId = unit.report({
    method: "setupChecklist",
    from: from,
    message: "The project checklist is being set up.",
    test: "Checklist calculation should be completed without error",
    steps: [
      "From workspace",
      "Review checklist calculations",
      "Confirm calculations are correct",
      "Click debug menu",
      "Reset checklist",
      "Confirm calculations are correct",
    ],
  });

  return new Promise((resolve, reject) => {
    try {
      // let project = cloneDeep(this.state.project);
      let initialCount = { total: 0, complete: 0 };
      let count = {
        checklist: initialCount,
        feature: initialCount,
      };
      let features = {};
      let checklist = {};

      let time = [0, 0];

      // Get the available features
      Object.values(Config.features)
        .sort((a, b) => a.priority - b.priority)
        .filter((feature) => feature.enabled === true)
        .forEach((feature) => {
          try {
            features[feature.slug] = {
              feature: Config.features[feature.slug],
              checklist: Config.checklist[feature.slug],
              steps: {},
            };
          } catch (error) {}
        });

      Object.keys(features).forEach((feature, i) => {
        checklist[feature] = {
          slug: feature,
          ...features[feature].checklist,
          ...{
            progress: {
              complete: 0,
              percent: 0,
              remaining: 0,
              total: Object.keys(features[feature].checklist).length,
            },
            time: [0, 0],
          },
        };

        let check = {
          passed: false,
          enabled: false,
        };

        count.feature = { total: 0, complete: 0 };

        let validation = false;
        let featureTime = [0, 0];

        Object.keys(checklist[feature].steps).forEach((step, i) => {
          if (checklist[feature].steps[step] == undefined) return;
          try {
            if (features[feature].checklist.steps[step] == true) {
              check.passed = true;
            } else if (features[feature].checklist.steps[step].enabled) {
              const complete = features[feature].checklist.steps[step].complete;

              validation = "";
              if (!complete) {
                check.passed = true;
              } else {
                if (typeof complete == "boolean" || complete == "true" || complete == "false") {
                  return complete;
                } else if (typeof complete == "string") {
                  if (complete.startsWith("checklist.")) {
                    validation = "features." + feature + "." + complete;
                  } else if (complete.startsWith("data.")) {
                    validation = "features." + feature + "." + complete;
                  } else if (complete.startsWith("project.")) {
                    validation = complete;
                  } else if (complete == "shared") {
                    validation = "shared.includes('" + feature + "')";
                  } else if (complete == "kb") {
                    validation = "kb.includes('" + features[feature].checklist.steps[step].kb + "')";
                  }
                } else {
                  return false;
                }
                check.passed = evaluateChecklist.call(this, validation.replace(/^\./, ""));
              }
            }
          } catch (error) {
            console.warn(error.message, feature, "~108");
          }

          check.enabled = true; // features[feature].checklist.steps[step];

          if (!this.features[feature].checklist) this.features[feature].checklist = {};
          this.features[feature].checklist[step] = check.passed;

          checklist[feature].steps[step] = {
            slug: step,
            complete: check.passed,
            enabled: check.enabled,
            visible:
              features[feature].checklist.steps[step].visible != null
                ? features[feature].checklist.steps[step].visible
                : true,
            title: features[feature].checklist.steps[step].title,
            actions: features[feature].checklist.steps[step].actions || [],
            kb: features[feature].checklist.steps[step].kb || null,
            priority: features[feature].checklist.steps[step].priority,
            number: 0,
            time: features[feature].checklist.steps[step].time || [0, 0],
            note: features[feature].checklist.steps[step].note || null,
          };

          featureTime[0] += features[feature].checklist.steps[step].time[0];
          featureTime[1] += features[feature].checklist.steps[step].time[1];

          time[0] += features[feature].checklist.steps[step].time[0];
          time[1] += features[feature].checklist.steps[step].time[1];

          if (check.enabled) {
            count.checklist.total++;
            count.feature.total++;
          }

          if (check.passed) {
            count.feature.complete++;
            count.checklist.complete++;
          }
        });

        checklist[feature].progress = {
          total: count.feature.total,
          complete: count.feature.complete,
          remaining: count.feature.total - count.feature.complete,
          percent: Math.floor((count.feature.complete / count.feature.total) * 100),
        };
        checklist[feature].time = featureTime;
      });

      // Nest features
      Object.values(checklist).forEach((feature) => {
        if (checklist[feature.nest]) {
          checklist[feature.nest].steps = { ...checklist[feature.nest].steps, ...feature.steps };

          checklist[feature.nest].progress.total += feature.progress.total;
          checklist[feature.nest].progress.complete += feature.progress.complete;
          checklist[feature.nest].progress.remaining +=
            checklist[feature.nest].progress.total - checklist[feature.nest].progress.complete;
          checklist[feature.nest].progress.percent = Math.floor(
            (checklist[feature.nest].progress.complete / checklist[feature.nest].progress.total) * 100
          );

          checklist[feature.nest].time[0] += feature.time[0];
          checklist[feature.nest].time[1] += feature.time[1];

          delete checklist[feature.slug];
        }
      });

      let progress = {
        total: count.checklist.total,
        complete: count.checklist.complete,
        remaining: count.checklist.total - count.checklist.complete,
        percent: Math.floor((count.checklist.complete / count.checklist.total) * 100),
        checklist: {},
      };

      let numbers = 1;

      Object.keys(checklist).forEach((feature) => {
        Object.entries(checklist[feature].steps)
          .sort((a, b) => a[1].priority - b[1].priority)
          .forEach((step, i) => {
            checklist[feature].steps[step[0]].number = numbers;
            numbers++;
          });
      });

      // Merging the checklist object into the state
      this.setState((prevState) => ({
        ...prevState,
        checklist: {
          progress: progress,
          time: time,
          features: checklist,
        },
      }));

      // this.update.project({
      //  data: book,
      //  from: "calculateChecklist",
      //  note: "Updated checklist.",
      // });
      unit.passed({ testId: testId });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error, "~217");
    }
  });
}

// Checklist functions

export function getChecklist(feature = null, from = "~224") {
  let testId = unit.report(
    {
      method: "getChecklist",
      test: "The user should see an entire checklist in a popup or dialog window.",
      message: "Get the checklist as it stands.",
    },
    from
  );
  try {
    if (feature == null) return this.state.checklist;
    unit.passed({ testId: testId });
    if (feature != null && this.state.checklist.features[feature]) return this.state.checklist.features[feature];
  } catch (error) {
    unit.passed({ testId: testId });
    this.errors.error(this.props.t("unexpectedError"), error, "~239");
    return [];
  }
}

export function getProgress(feature = null, from = "~244") {
  let testId = unit.report({
    method: "getProgress",
    message: "Testing here. " + feature,
    payload: feature
      ? "Returning progresss for " + feature
      : "No feature was passed as a request. Will Return project progress.",
    test: "The user should see an entire checklist in a popup or dialog window.",
    from: "~252",
  });
  try {
    if (feature == null) {
      return this.state.checklist.progress;
    } else if (feature != null && this.state.checklist.features[feature]) {
      return this.state.checklist.features[feature].progress;
    } else {
      this.errors.error(true, "It looks like there was an error getting progress for this project.", "~260");
      unit.failed({ testId: testId });
      return 0;
    }
  } catch (error) {
    const { errors } = this.props;
    unit.failed({ testId: testId, error: error });
    errors.warn(true, error, "~267");
    return 0;
  }
}

export function getSteps(feature, from = "~272") {
  let testId = unit.report(
    {
      method: "getSteps",
      test: "Should loop through the steps for the features and their status.",
      from: from,
      message: "Get a list of checklist steps available for this feature.",
    },
    from
  );
  try {
    let tmp = Object.keys(this.state.checklist.features[feature].steps).map((step) => ({
      ...this.state.checklist.features[feature].steps[step],
      ...{ feature: feature },
    }));
    unit.failed({ testId: testId });
    return tmp;
  } catch (error) {
    const { errors } = this.props;
    unit.failed({ testId: testId });
    errors.error(true, error, "~292");
    return [];
  }
}

export function getChecklistFlat(from = "~297") {
  let testId = unit.report(
    {
      method: "getSteps",
      test: "Should loop through the steps for the features and their status.",
      from: from,
      message: "Get a list of checklist steps available for this feature.",
    },
    from
  );
  try {
    let tmp = Object.keys(this.state.checklist.features)
      .map((feature) =>
        Object.keys(this.state.checklist.features[feature].steps).map(
          (step) => this.state.checklist.features[feature].steps[step]
        )
      )
      .flat(1);
    unit.failed({ testId: testId });
    return tmp;
  } catch (error) {
    unit.failed({ testId: testId });
    //this.errors.error(true, error, "~319");
    return [];
  }
}

export function getChecklistTime(feature = null, from = "~324") {
  if (feature == null) return this.state.checklist.time;
  try {
    return this.state.checklist.features[feature].time;
  } catch (e) {
    return [0, 0];
  }
}

export function evaluateChecklist(validate, language = this.state.project.languages[0], from = "~333") {
  let checklistId = unit.report(
    {
      method: "evaluateChecklist",
      test: "Checklit evaluation should be done for total complete, what is remaining and what steps should be hidden until other steps are complete.",
      message: "Get a list of checklist steps available for this feature.",
    },
    from
  );

  try {
    var criteria = [];
    if (typeof validate != "boolean") {
      if (validate.includes("{language}")) validate = validate.replace(".{language}.", "['" + language + "'].");
      var AO = "";
      if (typeof validate == "string" && validate.includes("||")) {
        AO = "or";
        criteria = validate.split("||");
      } else if (typeof validate == "string" && validate.includes("&&")) {
        AO = "and";
        criteria = validate.split("&&");
      } else {
        AO = null;
        criteria = [validate];
      }
      var co = "";
      var [ls, rs] = [null, null];
      let responses = criteria.map((criterium) => {
        if (criterium.includes("includes")) {
          co = "IN";
          [ls, rs] = [criterium, "true"];
        } else if (criterium.includes("==")) {
          co = "EQ";
          [ls, rs] = criterium.split("==");
        } else if (criterium.includes("!=")) {
          co = "NE";
          [ls, rs] = criterium.split("!=");
        } else if (criterium.includes(">")) {
          co = "GT";
          [ls, rs] = criterium.split(">");
        } else if (criterium.includes("<")) {
          co = "LT";
          [ls, rs] = criterium.split("<");
        }
        if (rs == "true") rs = true;
        if (rs == "false") rs = false;
        if (rs == '""') rs = "";
        try {
          let tls = eval("this.state.project." + ls, "~381");
          let result = false;
          if (co == "IN") result = tls === true;
          if (co == "EQ") result = tls === rs;
          if (co == "NE") result = tls !== rs;
          if (co == "GT") result = tls > rs;
          if (co == "LT") result = tls < rs;
          return result;
        } catch (error) {
          console.error(error, "~390");
          console.error("Attmped to evaluate", "this.state.project." + ls, "~391");
          console.error(this.state.project);
          return false;
        }
      });
      if (AO == null || AO == "or") {
        return responses.includes(true);
      } else {
        return !responses.includes(false);
      }
    } else {
      return validate;
    }
  } catch (error) {
    const { t, errors } = this.props;
    unit.report({ testId: checklistId, passed: false });
    errors.error(t("errorCalculatingChecklist"), error, "~407");
  }
}

export function recalculateChecklist(from = "~411") {
  setupChecklist.call(this, from).then(() => {
    // console.log("Done", "~413");
  });
}
