/** @format */
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";

import ChecklistComponent from "@Workspace/components/Checklist";

const Checklist = ({ open, toggleChecklist }) => {
  return (
    <Dialog open={open} onClose={toggleChecklist} aria-labelledby="checklist-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="checklist-dialog-title">
        Checklist
        <IconButton
          aria-label="close"
          onClick={toggleChecklist}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ChecklistComponent />
      </DialogContent>
    </Dialog>
  );
};

export default Checklist;
