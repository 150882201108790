/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/drop");

export function onDrop(props, from = "~7") {
  unit.report({
    method: "onDrop",
    message: "Drop and element onto the canvas.",
    payload: props,
    from: from,
    test: "Elements dropped onto the canvas should display.",
  });

  const { performing } = this.props;
  const { type } = props;

  if (!props) return;

  try {
    // THIS SHOULD BE DYNAMIC

    // Image
    if (type == "image") return this.element.image.add(props, false, "~25");

    // ISBN
    if (type == "isbn") return this.element.elements.isbn(props, "~28");

    // HumanMade
    if (type == "humanmade") return this.element.elements.humanmade(props, "~31");

    // Copyright
    if (type == "copyright") return this.element.elements.copyright(props, "~34");

    // Shape
    if (type == "shape") return this.element.elements.shape(props, "~37");

    // Add some warning here
    performing.set.updating("error", "~40");
  } catch (_) {
    performing.set.updating("error", "~42");
  }
}

export function getDroppableStyle() {
  this.unit.report({
    method: "droppableStyle",
    steps: [],
    test: "When dragging and dropping the style of the spread should indicate that the spread is a dropzone. An outline should be visible.",
  });

  if (!this.spread.ready() || !this.state.sized)
    return {
      ...this.state.dimensions.spread,
      ...{
        position: "absolute",
        zIndex: 1300,
      },
    };

  return {
    ...this.state.dimensions.spread,
    ...{
      position: "absolute",
      zIndex: 1300,
    },
  };
}
