/** @format */
import Beta from "@Workspace/Beta";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withActions } from "actions";
import { CaptureControls } from "capture";
import { ComponentError, TinyError } from "errors";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import { withUser } from "user";
import { version } from "version";

const StyledBox = styled(Box)(({ theme }) => ({
  "&.menu.bottom": {
    position: "fixed",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    transform: "translateZ(0px)",
    flexGrow: 1,
    padding: 5,
    zIndex: 1,
  },

  "&.touring": {
    backgroundColor: "transparent",
    zIndex: 1257,
  },

  "& .button": {
    margin: "5px",
    borderRadius: "5px",
    borderColor: "rgba(0,0,0,.12)",
    color: "rgba(0,0,0,.5)",
    backgroundColor: theme.palette.mode == "light" ? "#FFF" : "#222",
    ":hover": {
      backgroundColor: theme.palette.mode == "light" ? "#FFF" : "#222",
    },
  },

  "& .button.Mui-disabled": {
    border: "1px solid " + theme.palette.primary + " !important",
  },
}));

class Bottom extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = { border: 0 };
    this.unit = this.props.unit.new(this.constructor.name);
  }

  render() {
    const { children, workspace, t, user } = this.props;
    const { meta } = user;

    // Get if assist is opened
    const opened = workspace.assist.opened();

    // Set an offset margin for beta assist
    let margin = {};

    if (opened) margin = user.meta.get("rightDesk") ? { marginRight: "33vw" } : { marginLeft: "33vw" };

    if (!workspace.ready.get("all")) return <></>;
    return (
      <ComponentError>
        <StyledBox className="menu bottom">
          {meta.get("rightDesk") ? (
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              <Box id="assist-button-portal"></Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                bottom: "17px",
                right: "20px",
                color: "#67232F",
                fontSize: "10.5px",
              }}
            >
              BETA v{version}
            </Box>
          )}
          <Stack direction="row" spacing={0} justifyContent="space-around" alignItems="center">
            <Box sx={{ width: "33vw" }}>&nbsp;</Box>
            <ComponentError>
              <Stack
                sx={{
                  ...{
                    width: "33vw",
                  },
                  ...margin,
                }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                {children && children}
              </Stack>
            </ComponentError>
            <Box sx={{ width: "33vw", textAlign: "right" }}>
              <TinyError>
                <Beta enabled={true}></Beta>
              </TinyError>
            </Box>
          </Stack>
          <CaptureControls message={t("snapshotMessage")} />
        </StyledBox>
      </ComponentError>
    );
  }
}

export default withActions(withLocales(withUser(Bottom)));
