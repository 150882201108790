/** @format */

import { nanoid } from "nanoid";
import { Functional } from "unit";
import CopyrightTemplate from "../../config/templates/copyright.json";

const unit = Functional.unit("designer/copyright");

export function addCopyrightFromElements(props, from = "~9") {
  unit.report({
    method: "addCopyrightFromElements",
    payload: props,
    action: true,
    analyze: true,
    test: "Drags a copyright from elements to spread.",
    from: from,
  });
  const { t, errors } = this.props;
  return new Promise((resolve, reject) => {
    try {
      const id = nanoid();
      const { top, left } = props;

      // Adding this to capture errors i there are no fonts
      let family = this.edit.font.family.get()?.[0];

      if (!family) family = "Comfortaa";

      const element = {
        ...CopyrightTemplate,
        id: id,
        font: { ...CopyrightTemplate.font, family },
        style: [
          {
            ...CopyrightTemplate.style[0],
            width: 600,
            height: 150,
            left: left,
            top: top,
          },
        ],
      };
      this.element.add(element).then(() => {
        resolve();
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~47");
      reject(error);
    }
  });
}
