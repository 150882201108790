/** @format */
import ThumbContext from "@Workspace/Components/Thumb";
import UsableContext from "@Workspace/Components/Usable/context";
import { withWorkspace } from "@Workspace/Context";
import Menus from "@Workspace/Menus";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageError, TinyError, withErrors } from "errors";
import { withMedia } from "media";
import { withPerforming } from "performing";
import { Component as ReactComponent } from "react";
import Draggable from "react-draggable";
import { withUI } from "ui";
import { withUnit } from "unit";
import { withUser } from "user";
import WorkspaceWidgets from "../components/Widgets";
import WorkspaceWorkers from "../components/Workers";
import WorkspaceDialogs from "../dialogs";

// Load the feature
import Feature from "./components/Feature";

// Detect the device (limit iPad and iPhone)
import Detect from "./components/Detect";

// The beta assist window
import Assist from "../components/Assist";

const StyledBox = styled(Box)(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.mode == "light" ? "#FAFAFA" : "#646464",
  },
  "& .desktop": {
    margin: "0",
    overflow: "visible",
  },
  "& #zoom": {
    transition: "0.2s ease",
    minHeight: "100vh",
    minWidth: "100vw",
  },
  "& #zoom:active": {
    cursor: "grabbing",
  },
  "& #zoom:active .desktop": {
    pointerEvents: "none",
  },
}));

class Workspace extends ReactComponent {
  constructor(props) {
    super(props);
    this.unit = this.props.unit.new(this.constructor.name);
  }

  render() {
    const { workspace, user } = this.props;
    const { settings, reposition } = workspace;
    const { x, y } = reposition.get();

    // Offset desktop for beta assist
    const offset = workspace.assist.opened() ? (window.innerWidth * 0.33) / 2 : 0;

    return (
      <StyledBox sx={{ overflowX: "hidden" }}>
        <ThumbContext>
          <UsableContext>
            <Detect />
            <Menus />
            <Box className="workspace">
              <Assist {...this.props} />

              <Box
                data-component="assist-offset"
                sx={
                  user.meta.get("rightDesk")
                    ? {
                        marginLeft: `-${offset + 40}px`,
                      }
                    : {
                        marginLeft: `${offset}px`,
                      }
                }
              >
                <Draggable
                  defaultPosition={{ x: 0, y: 0 }}
                  position={{ x: x, y: y }}
                  disabled={!reposition.available.get()}
                  onStop={reposition.set}
                  data-component="draggable"
                >
                  <Box
                    className="desktop"
                    id="desktop"
                    sx={{
                      cursor: reposition.available.get() ? "grabbing" : "auto",
                    }}
                  >
                    <Box
                      id="workspace-portal"
                      sx={{
                        width: "100vw",
                        height: "100%",
                        position: "absolute",
                        zIndex: 0,
                      }}
                    />
                    <Box
                      id="zoom"
                      data-component="zoomable"
                      data-desk={settings.desk.toString()}
                      style={workspace.desk.css()}
                    >
                      <PageError>
                        <Feature
                          onMouseEnter={() => {
                            reposition.available.set(false);
                          }}
                          onMouseLeave={() => {
                            reposition.available.set(true);
                          }}
                        />
                      </PageError>
                    </Box>
                  </Box>
                </Draggable>
              </Box>
            </Box>
            <TinyError>
              <WorkspaceDialogs />
            </TinyError>
            <TinyError>
              <WorkspaceWidgets />
            </TinyError>
            <TinyError>
              <WorkspaceWorkers />
            </TinyError>
          </UsableContext>
        </ThumbContext>
      </StyledBox>
    );
  }
}

// # TODO this old catchall method needs to be moved up a layer. Pushing down props is a problem with unit tests
export default withWorkspace(withMedia(withUI(withPerforming(withUnit(withUser(withErrors(Workspace)))))));
