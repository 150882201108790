/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/label");

export function setElementLabel(text, from = "~7") {
  unit.report({
    method: "setElementLabel",
    payload: text,
    test: "Label for edited elements should display and show proper information.",
    from: from,
  });

  const { ui } = this.props;

  ui.close();

  try {
    this.setState({ label: text, moveable: true });
  } catch (_) {}
}

export function showElementLabel(from = "~24") {
  unit.report({
    method: "showElementLabel",
    test: "The label for the element being edited shows.",
    from: from,
  });
  try {
    this.setState({ label: null });
  } catch (_) {}
}

export function hideElementLabel(from = "~35") {
  unit.report({
    method: "hideElementLabel",
    test: "The label for the element being edited hides.",
    from: from,
  });
  try {
    this.setState({ label: null, moveable: false });
  } catch (_) {}
}
