/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/tools");

export function getTool(tool = null, from = "~7") {
  unit.report({
    method: "getTool",
    from: from,
    test: tool + " should be indicated as selected or not.",
  });
  return tool != null ? tool == this.state.tool : this.state.tool;
}

export function setTool(tool = null, from = "~16") {
  unit.report({
    method: "setTool",
    from: from,
    test: tool + " should be indicated as selected or not.",
  });

  let edit = {};

  if (tool == "select") {
    edit = {
      selectable: true,
      draggable: true,
      resizable: true,
      rotatable: true,
      warpable: false,
    };
  }

  if (tool == "text") {
    edit = {
      selectable: false,
      draggable: false,
      resizable: false,
      rotatable: false,
      warpable: false,
    };
  }

  if (tool == "resize") {
    tool = "select";
    edit = {
      selectable: true,
      draggable: true,
      resizable: true,
      rotatable: true,
      warpable: false,
    };
  }

  if (tool == "warp") {
    tool = "select";
    edit = {
      selectable: true,
      draggable: true,
      resizable: false,
      rotatable: true,
      warpable: true,
    };
  }

  this.setState({ tool: tool, edit: edit });
}

export function toggleTool(from = "~70") {
  unit.report({
    method: "selectedTool",
    action: true,
    analyze: true,
    from: from,
    test: "Toggling any tool should show the states of it being on/off.",
  });
  setTool(this.state.tool == "text" ? "select" : "text", "~78");
}
