/** @format */
import { Box, Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import capitalize from "capitalize";
import modifier from "modifier";
import { Component as ReactComponent, createRef } from "react";

import DynamicIcon from "dynamicicon";
import key from "key";

import { withWorkspace } from "@Workspace/Context";
import { ComponentError, TinyError } from "errors";
import { withLocales } from "locales";
import { withTour } from "tour";
import { withUI } from "ui";
import { withUnit } from "unit";

// The different types of checklist
import Feature from "./components/Feature"; // full by feature
import Limited from "./components/Limited"; // limited by a number

const StyledBox = styled(Box)(() => ({
  "&": { maxHeight: "100%" },
}));

class Checklist extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      feature: null,
      show: "all",
      limit: 999,
    };

    this.unit = this.props.unit.new(this.constructor.name);

    this.modifiers = modifier(0, 20);
    this.ref = createRef();
  }

  deskSelected = () => {
    let { workspace } = this.props;
    let icon = workspace.menu.features.selected(workspace.feature.selected()).icon;
    return (
      <>
        <span className="selectedIcon">
          {icon ? <DynamicIcon icon={icon} /> : <DynamicIcon icon={"fa-paintbrush"} />}
        </span>
        <span className="feature">{capitalize(workspace.desk.get()) || null}</span>
      </>
    );
  };

  select = () => {
    return {
      feature: (feature) => this.setState({ feature: feature }),
      overview: () => this.setState({ feature: null }),
    };
  };

  enabledFeatures = () => {
    const { workspace } = this.props;
    return Object.entries(workspace.checklist.get().features)
      .filter(([_, value]) => value.enabled)
      .map(([key]) => key);
  };

  componentDidMount() {
    if (this.props.showOnly) {
      this.setState({ show: this.props?.show });
    }
    if (this.props.limit) {
      this.setState({ limit: this.props?.limit });
    }
  }

  render() {
    const { workspace, t } = this.props;

    return (
      <StyledBox sx={{ marginTop: "20px" }}>
        {this.state.showOnly == "all" ? (
          <ComponentError>
            {this.enabledFeatures().map((feature, i) => (
              <TinyError {...key("workspace", "menu", "bottom", "checklist", "feature", feature, i)}>
                <Feature {...this.props} i={i} feature={workspace.checklist.get(feature)} />
              </TinyError>
            ))}
          </ComponentError>
        ) : (
          <>
            <TinyError>
              <Limited {...this.props} limit={this.state.limit} />
            </TinyError>
            {!this.state.show == "all" && (
              <>
                <Divider />
                <Box sx={{ padding: "5%", textAlign: "right" }}>
                  <Button variant="contained" color="success" onClick={() => this.setState({ show: "all" })}>
                    {t("showAll")}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </StyledBox>
    );
  }
}

export default withLocales(withUnit(withWorkspace(withTour(withUI(withLocales(Checklist))))));
