/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/resize");

let initialSize = { width: 0, height: 0 };
let initialPosition = { left: 0, top: 0 };
let centerPosition = { left: 0, top: 0 };

export function onResize(event, from = "~11") {
  unit.report({
    method: "onResize",
    payload: event,
    from: from,
    test: "Resizing should be smooth and the label should reflect properly the size.",
  });

  if (event.isFirstDrag) {
    initialSize = {
      width: parseFloat(event.target.style.width),
      height: parseFloat(event.target.style.height),
    };

    initialPosition = {
      left: parseFloat(event.target.style.left),
      top: parseFloat(event.target.style.top),
    };

    // Calculate the center position of the element
    centerPosition = {
      left: initialPosition.left + initialSize.width / 2,
      top: initialPosition.top + initialSize.height / 2,
    };

    return;
  }

  // Use the new width and height directly from the event.
  let newWidth = event.width;
  let newHeight = event.height;

  // Get bounds
  const { top, right, bottom, left } = this.state.bounds;

  // Calculate new left and top positions based on center position.
  let newLeft = centerPosition.left - newWidth / 2;
  let newTop = centerPosition.top - newHeight / 2;

  // Check if the new position would exceed the bounds
  // Right boundary
  if (newLeft + newWidth > right) {
    newWidth = right - newLeft;
  }

  // Bottom boundary
  if (newTop + newHeight > bottom) {
    newHeight = bottom - newTop;
  }

  // Left boundary
  if (newLeft < left) {
    newWidth -= left - newLeft;
    newLeft = left;
  }

  // Top boundary
  if (newTop < top) {
    newHeight -= top - newTop;
    newTop = top;
  }

  // # TODO - this should be broken out better
  // (all elements should be able to be resized)
  // Resize the vertical on the text box
  if (this.element.type("text") || this.element.type("copyright")) {
    // Get the current size of the text
    const { height } = this.element.text.size.get(this.element.selected());

    // Caluclating the width is easy enough
    newHeight = height;
  }

  if (newHeight < 100 || newWidth < 100) return;

  try {
    this.element.style.set(
      {
        event: event,
        label: `${parseInt(newWidth)}x${parseInt(newHeight)}`,
        style: {
          width: newWidth,
          height: newHeight,
          left: newLeft,
          top: newTop,
        },
      },
      "~98"
    );
  } catch (_) {}
}

export function onResizeGroup(event) {
  unit.report({
    method: "onResizeGroup",
    payload: event,
    test: "A group of elements being resized should do so smoothly and the final position should be saved to the db. Confirmed with reload.",
  });
  // This doesn't look right
  event.events.forEach((event) => {
    this.element.style.set(
      {
        event: event,
        label: `${parseInt(event.width)}x${parseInt(event.height)}`,
        style: {
          width: event.width,
          height: event.height,
        },
      },
      "~120"
    );
    // onResize.call(this, event);
  });
}
