/** @format */

import { styled } from "@mui/material/styles";
import { useRef } from "react";
import { useDrag, useDragLayer } from "react-dnd";

const DraggableBox = styled("div")(({ theme, isDragging }) => ({
  border: "1px dashed gray",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  padding: "1px",
  minWidth: "100%",
  minHeight: "100%",
  overflow: "hidden",
  cursor: "move",
  float: "left",
  borderRadius: "3px",
  margin: 0,
  opacity: isDragging ? 0.4 : 0.5,
  "&:hover": {
    opacity: 1,
    borderColor: theme.palette.success.light, // Change border color to red on hover
  },
}));

const Draggable = function Box(props) {
  const { children, id, name, url, onDragStart, onDragEnd, onDrop, sx } = props;
  const clientOffset = useRef({ x: 0, y: 0 }); // Initialize a ref to store the latest clientOffset
  const [_, drag] = useDrag(() => ({
    type: "box",
    item: { id, name, onDrop },
    isDragging: (monitor) => {
      const offset = monitor.getClientOffset();
      if (offset) clientOffset.current = monitor.getClientOffset();
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const { x, y } = clientOffset.current;
        item.onDrop({ top: y || 0, left: x || 0, y: y || 0, x: x || 0 });
      }
      if (onDragEnd) onDragEnd();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  useDragLayer((monitor) => ({
    clientOffset: monitor.getClientOffset(),
  }));

  return (
    <DraggableBox
      onMouseDown={onDragStart}
      onMouseUp={onDragEnd}
      ref={drag}
      role="cell"
      sx={sx}
      draggable-id={`box-${id}`}
    >
      {children}
    </DraggableBox>
  );
};

export default Draggable;
