/** @format */
import { Component as ReactComponent } from "react";

import RightMenu from "@App/Menus/Right";
import { withActions } from "actions";
import { withBroadcast } from "broadcast";
import { withUI } from "ui";

class Right extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: "assist",
    };
  }
  render() {
    return <RightMenu></RightMenu>;
  }
}

export default withBroadcast(withUI(withActions(Right)));
