/** @format */
import { Box, Divider, LinearProgress, Typography } from "@mui/material";

import { useLocales } from "locales";
import { Functional } from "unit";

import Steps from "./components/Steps";

const unit = Functional.unit("Feature");

export default function Feature(props) {
  unit.report({
    method: "rendered",
    test: "",
    message: "Generic",
    from: "~16",
  });
  const { workspace, feature } = props;

  // Get the languages
  const { t } = useLocales();

  // Here are the colors of the buttons
  const color = () => {
    if (workspace.progress(feature.slug, "~25").percent < 33) return "info";
    if (workspace.progress(feature.slug, "~26").percent < 66) return "secondary";
    if (workspace.progress(feature.slug, "~27").percent < 99) return "primary";
    return "success";
  };

  // return it
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Box component="h5" sx={{ marginTop: "0px" }}>
          {t(feature.title)}
        </Box>
        <Box
          sx={{
            display: "flex",
            marginBottom: "20px",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              sx={{ height: "10px", borderRadius: "3px" }}
              variant="determinate"
              color={color(workspace.progress(feature.slug, "~51").percent || 0)}
              value={workspace.progress(feature.slug, "~52").percent || 0}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${
              workspace.progress(feature.slug, "~57").percent || 0
            }%`}</Typography>
          </Box>
        </Box>
      </Box>
      <Steps {...props} />
      <Box sx={{ padding: "5%" }}>
        <Divider />
      </Box>
    </>
  );
}
