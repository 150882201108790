/** @format */
import { Box, MenuList } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ComponentError } from "errors";
import key from "key";

import { Functional } from "unit";
import Step from "../Step";

const unit = Functional.unit("List");

const StyledBox = styled(Box)(() => ({
  "&": {
    padding: "0px",
    width: "100%",
    overflow: "scroll",
  },

  "& .grid": {
    minWidth: "420px",
    maxWidth: "420px",
    width: "420px",
    padding: "40px 20px 20px 20px",
    marginBottom: "20px",
  },

  "& .checklistButton": {
    marginLeft: "0px !important",
    borderRadiusTopLeft: "0px !important",
    borderRadiusBottomLeft: "0px !important",
    borderLeft: "1px solid rgba(12,12,12,.5)",
  },

  "& .waiting": {
    fontStyle: "italic",
  },
  "& .row": {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  "& .progress": {
    width: "100%",
    position: "absolute",
    bottom: "0px",
    padding: "20px",
    left: "0px",
    backgroundColor: "#121212",
    borderTop: "1px solid rgba(255,255,255,.12)",
  },
  "& .list": {
    marginLeft: "-0px",
  },
}));

export default function Limited(props) {
  unit.report({
    method: "rendered",
    test: "",
    message: "Generic",
    from: "~61",
  });

  const { workspace } = props;

  return (
    <StyledBox>
      <MenuList sx={{ width: "100%" }}>
        <ComponentError>
          {workspace.checklist
            .flat()
            .filter((step) => step.visible && step.enabled && step.complete == false)
            .sort((a, b) => a.number - b.number)
            .slice(0, props.limit)
            .map((step, j) => (
              <Step
                {...props}
                step={step}
                {...key("workspace", "menu", "bottom", "features", "checklist", "feature", "step", j)}
              />
            ))}
        </ComponentError>
      </MenuList>
    </StyledBox>
  );
}
