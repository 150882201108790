/** @format */
import { withWorkspace } from "@Workspace/Context";
import { Box } from "@mui/material";
import { Component as ReactComponent, Suspense, lazy } from "react";
import { withUI } from "ui";
import { withUnit } from "unit";

const Share = lazy(() => import("./components/Share"));
const Feature = lazy(() => import("./components/Feature"));
const Intro = lazy(() => import("./components/Intro"));
const Support = lazy(() => import("./components/Support"));

const Boards = lazy(() => import("../boards"));

class Controller extends ReactComponent {
  componentDidMount() {
    this.unit = this.props.unit.new(this.constructor.name);
  }

  render() {
    const { share, feature, intro } = this.props.ui.dialogs;

    return (
      <Box>
        <Suspense fallback={null}>
          <Share {...this.props} {...share?.props} />
        </Suspense>
        <Suspense fallback={null}>
          <Feature {...this.props} {...feature?.props} />
        </Suspense>
        <Suspense fallback={null}>
          <Intro {...this.props} {...intro?.props} />
        </Suspense>
        <Suspense fallback={null}>
          <Boards {...this.props} />
        </Suspense>
        <Suspense fallback={null}>
          <Support {...this.props} />
        </Suspense>
      </Box>
    );
  }
}

export default withUI(withUnit(withWorkspace(Controller)));
