/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Designer");

export function onWarp(event, from = "~7") {
  unit.report({
    method: "onWarp",
    payload: event,
    from: from,
    test: "Warping should be smooth and the label should reflect properties of the warp.",
  });

  const { t } = this.props;

  if (!event?.target) return;

  const transform = this.element.style.transform(event.transform, "~19");

  try {
    this.element.style.set(
      {
        event: event,
        label: t("spooky"),
        style: {
          transform: { matrix3d: transform.matrix3d },
        },
      },
      "~30"
    );
  } catch (error) {
    console.error(error, "~33");
  }
}
