/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Designer");

export function onDrag(event) {
  unit.report({
    method: "onElementDrag",
    message: "Put this back in.",
    payload: event,
    test: "Elements dragging should do so smoothly and the final position should be saved to the db. Confirmed with reload.",
  });

  if (!event) return;

  const { top, left } = event;

  try {
    this.element.style.set(
      {
        event: event,
        label: left.toFixed(1) + " x " + top.toFixed(1),
        style: {
          left: left,
          top: top,
        },
      },
      "~29"
    );
  } catch (error) {
    console.error(error, "~32");
  }
}

export function onDragGroup(event) {
  unit.report({
    method: "onDragGroup",
    payload: event,
    test: "A group of elements being dragged should do so smoothly and the final position should be saved to the db. Confirmed with reload.",
  });
  // This doesn't look right
  event.events.forEach((event, i) => {
    onDrag.call(this, event);
  });
}
