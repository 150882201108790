/**
 * Media
 *
 * @format
 */

export async function deleteMedia({ id }, confirm = false) {
  const { ui, t, performing } = this.props;
  if (!confirm) {
    this.unit.report({
      method: "deleteMedia",
      analyze: true,
      action: true,
      test: "When the delete icon is pressed a confirmation dialog should be shown, ",
    });
    ui.confirm.open({
      title: t("confirm"),
      text: t("confirmDeleteMedia"),
      dismiss: {
        onClick: () => {
          this.unit.report({
            method: "deleteMedia",
            analyze: true,
            action: true,
          });
        },
        text: t("cancel"),
      },
      confirm: {
        onClick: () => {
          performing.set.updating("updating", "~31");
          deleteMedia.call(this, { id: id }, true);
        },
        text: t("confirm"),
        color: "error",
      },
    });
  } else {
    this.unit.report({
      method: "deleteMedia",
      event: "confirmDeleteMedia",
      analyze: true,
      action: true,
      payload: { type: this.state.type, id: id },
      test: "After clicking confirm, the user's media should be deleted",
    });

    // # TODO DELETE FROM PROJECT

    await this.props.media.delete(id);

    performing.set.updating("success", "~52");
  }
}

export function canDelete(media) {
  const { user } = this.props;
  return user.admin() || media.userId == user.id;
}

export function onSuccess(props) {
  // # TODO this is on a timer because the onSuccess upload isn't passing back a promise from the media component
  const { errors, t, performing, media } = this.props;
  performing.set.updating("updating", "~64");
  let attempts = 0;
  const maxAttempts = 30; // 60 seconds / 2-second intervals
  const intervalId = setInterval(async () => {
    let response = await media.get(props.id);

    // If the response is not null, or maximum attempts are reached
    if (response !== null || attempts >= maxAttempts) {
      clearInterval(intervalId); // Clear the interval
      if (response === null && attempts >= maxAttempts) {
        // Handle error, as response is null after maximum attempts
        errors.error(t("errorUploadingElement"), t("errorUploadingElement"), "~75");
        performing.set.updating("error", "~76");
      } else {
        performing.set.updating("success", "~78");
      }
    }

    attempts++;
  }, 2000); // 2-second interval
}
