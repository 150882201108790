/**
 * A class for registering and handling keyboard shortcuts.
 *
 * @format
 * @class
 */

import isHotkey from "is-hotkey";
import commandKeys from "./config/index.json";

import isSSR from "isssr";

class Keypress {
  /**
   * Creates an instance of Keypress.
   */
  constructor() {
    /**
     * A registry of keyboard shortcuts and their associated callbacks.
     *
     * @type {Object.<string, {callback: Function, slug: string}>}
     */
    this.keyRegistry = {};

    /**
     * The event handler for keydown events.
     *
     * @param {KeyboardEvent} event - The keydown event.
     */
    this.handleKeypress = (event) => {
      const keyRegistry = this.keyRegistry;

      for (const registeredKey in keyRegistry) {
        if (isHotkey(registeredKey, event)) {
          keyRegistry[registeredKey].forEach(({ callback }) => callback());
        }
      }
    };
    try {
      if (!isSSR) document.addEventListener("keydown", this.handleKeypress);
    } catch (_) {}
  }

  /**
   * Adds a keyboard shortcut to the registry.
   *
   * @param {string} key - The name of the shortcut key.
   * @param {Function} callback - The function to be called when the shortcut is triggered.
   * @param {string} [slug=null] - An optional slug to identify the callback.
   */
  add(key, callback, slug = null) {
    const commandKey = commandKeys[key];
    if (commandKey) {
      this.addRaw(commandKey, callback, slug);
    } else {
      console.warn(`Unknown key '${key}'`);
    }
  }

  /**
   * Adds a keyboard shortcut to the registry using the raw key value.
   *
   * @param {string} key - The raw value of the shortcut key.
   * @param {Function} callback - The function to be called when the shortcut is triggered.
   * @param {string} [slug=null] - An optional slug to identify the callback.
   */
  addRaw(key, callback, slug = null) {
    const keyRegistry = this.keyRegistry;
    if (!keyRegistry[key]) {
      keyRegistry[key] = [];
    }

    const existingCallbacks = keyRegistry[key];
    if (slug && existingCallbacks.find((entry) => entry.slug === slug)) {
      console.warn(`Callback with slug '${slug}' for key '${key}' already exists. Skipping registration.`);
      return;
    }

    if (existingCallbacks.some((entry) => entry.callback === callback)) {
      console.warn(`Callback for key '${key}' already exists. Skipping registration.`);
      return;
    }

    keyRegistry[key].push({ callback, slug });
  }
}

/**
 * An instance of the Keypress class.
 *
 * @type {Keypress}
 */
const keypressInstance = new Keypress();
export { keypressInstance as Keypress };
