/** @format */

import { useUser } from "user";

// Returns a wrapping component
export default function Beta({ enabled = false, children }) {
  const user = useUser();
  if (user.meta.get("beta") != enabled) return;
  return <>{children}</>;
}

// Returns the state of beta or checks the state
export function beta(check) {
  const user = useUser();
  if (check == null || check == undefined) return user.meta.get("beta");
  return user.meta.get("beta") == check;
}
