/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Designer");

/* Selecting elements */

export function getSelected(from = "~9") {
  unit.report({
    method: "getSelectedData",
    message: "Gets the selected element data.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    return this.state.elements;
  } catch (_) {
    return [];
  }
}

export function getSelectedData(from = "~23") {
  unit.report({
    method: "getSelectedData",
    message: "Gets the selected element data.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    return getSelected.call(this, "~31").map((element) => ({
      type: element.getAttribute("data-type"),
      id: element.getAttribute("data-id"),
      index: parseInt(element.getAttribute("data-index"), 10),
      frame: element.getAttribute("data-frame") || this.state.frame.number,
    }));
  } catch (_) {
    return [];
  }
}

export function getSelectedElement(respond = "data", from = "~42") {
  unit.report({
    method: "getSelectedElement",
    message: "Gets the selected elements.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    let selected;
    if (respond == "data") {
      selected = getSelectedData.call(this, "~52");
    } else {
      selected = getSelected(this, "~54");
    }
    return selected.length > 0 ? selected[0] : null;
  } catch (_) {
    return null;
  }
}

export function getSelectedIndex(layer) {
  try {
    const { index } = this.element.selected();
    return layer !== undefined ? layer == index : index;
  } catch (_) {
    return null;
  }
}

export function getSelectedElements(respond = "data", from = "~71") {
  unit.report({
    method: "getSelectedElement",
    message: "Gets the selected elements.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    let selected;
    if (respond == "data") {
      selected = getSelectedData.call(this, "~81");
    } else {
      selected = getSelected.call(this, "~83");
    }
    return selected;
  } catch (_) {
    return [];
  }
}

export function hasSelected(from = "~91") {
  unit.report({
    method: "hasSelected",
    message: "Checks if an element is selected.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    return getSelected.call(this, "~99").length > 0;
  } catch (e) {
    return false;
  }
}

export function getSelectedElementType(type = null, from = "~105") {
  unit.report({
    method: "getSelectedElement",
    message: "Checks if an element is selected.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  // This is pretty hacky
  const group = (type) => {
    if (["text", "copyright"].includes(type)) return "text";
    return type;
  };
  try {
    // get the selected data
    const selected = getSelectedData.call(this, "~119");

    // if nothing is selected, return false if we've passed an argument, otherwise null
    if (selected.length != 1) return type ? false : null;

    // We have a selected element, so return the type if we've passed an argument, otherwise the type or null
    return type ? group(selected[0].type) == type : selected[0].type || null;
  } catch (_) {
    // if there's an error, return false if we've passed an argument, otherwise null
    return type ? false : null;
  }
}

/* Onselect, deselect */

export function selectElements(event, shiftPressed = false) {
  // Report and logs
  unit.report({
    method: "onSelectEnd",
    message: "Handles the end of a selection event, including deselect",
    test: "Check if the selected and deselected elements are updated correctly",
    from: "~140",
  });

  const { performing } = this.props;

  if (this.state.controls) return;

  try {
    let newElements;

    if (shiftPressed) {
      // Create a Set from existing elements for uniqueness
      const currentElements = new Set(this.state.elements.map((el) => el.dataset.id));

      // Add newly selected elements to the set
      event.added.forEach((el) => currentElements.add(el.dataset.id));

      newElements = Array.from(currentElements)
        .map((id) => document.querySelector(`[data-id='${id}']`))
        .filter(Boolean);
    } else {
      // Replace the array if shift is not pressed
      newElements = event.selected;
    }

    // Deselect if nothing is selected
    if (event.selected.length === 0) {
      newElements = [];
    }

    // Check for changes in selection and update state
    if (
      newElements.length !== this.state.elements.length ||
      !newElements.every((el, index) => el.dataset.id === this.state.elements[index].dataset.id)
    ) {
      this.setState({ elements: newElements, revised: Date.now() });
    }

    this.controls.menu.close();
  } catch (_) {
    performing.set.updating("error", "~180");
  }
}

export function deslectElements(from = "~184") {
  unit.report({
    method: "deslectElements",
    message: "Handles clicking of a picture in workspace this.",
    test: "When clicking on an element it is set to the selected element",
    from: from,
  });
  const { performing } = this.props;
  try {
    this.setState({ elements: [], revised: Date.now() });

    this.controls.menu.close();
  } catch (error) {
    performing.set.updating("error", "~197");
  }
}

export function refreshElements(from = "~201") {
  unit.report({
    method: "selectElements",
    message: "Handles clicking of a picture in workspace this.",
    test: "When clicking on an element it is set to the selected element",
    from: from,
  });
  const { performing } = this.props;
  try {
    const id = this.element.selected("data")?.id;
    if (id) {
      this.setState({ elements: [], revised: Date.now() }, () => {
        const elements = document.querySelectorAll(`[data-id="${id}"]`);
        this.setState({ elements: [...elements], revised: Date.now() });
      });
    }
  } catch (_) {
    performing.set.updating("error", "~218");
  }
}

export function getSelectedElementAttributes(type = "array", from = "~222") {
  unit.report({
    method: "getSelectedElementAttributes",
    from: from,
  });
  try {
    const { workspace } = this.props;
    const feature = workspace.feature.selected();
    const spread = this.state?.spread?.number || 0;
    const element = this.element.selected();
    const frame = this.state.frame.number || 0;
    if (type == "array") {
      return [feature, spread, element.index, element.id, frame, element.type];
    } else {
      return { feature: feature, spread, id: element.id, index: element.index, frame, type: element.type };
    }
  } catch (_) {
    return false;
  }
}
