/** @format */
import { Box, Button, Stack, Tooltip } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { ComponentError, TinyError } from "errors";
import React, { useState } from "react";

import { version } from "version";

import { useWorkspace } from "@Workspace/Context";
import { useActions } from "actions";
import { useLocales } from "locales";
import { Tip, useTour } from "tour";
import { useUser } from "user";

import Checklist from "./components/Checklist";
import Features from "./components/Features";

export default function Left() {
  const workspace = useWorkspace();
  const { t } = useLocales();
  const tour = useTour();
  const { meta } = useUser();

  const action = useActions().run.action;

  const [checklist, setChecklist] = useState(false);

  if (!workspace.ready.get()) return <></>;

  // Start up the tour
  const startTour = () => {
    // Get the selected feature
    const feature = workspace.feature.selected();

    workspace.tour.start(feature);
  };

  const toggleChecklist = () => {
    setChecklist(!checklist);
  };

  return (
    <ComponentError>
      <Stack
        alignItems="space-between"
        sx={{
          minHeight: "100vh",
          width: "40px",
          position: "fixed",
          alignItems: "center",
          zIndex: 1200,
        }}
      >
        <Stack sx={{ height: "100%", minHeight: "calc(100vh - 60px)", paddingTop: "160px" }}>
          <TinyError>
            <Tooltip title={t("project")} placement="left">
              <Box sx={{ marginBottom: "15px" }}>
                <Tip title={t("tourProject")} name="project" tour={tour.name} placement="left">
                  <Button variant="small-tab" onClick={() => action.project.open(0)}>
                    <DynamicIcon icon={"fa-gear"} />
                  </Button>
                </Tip>
              </Box>
            </Tooltip>
          </TinyError>
          <Stack
            sx={{
              paddingRight: "15px",
              marginRight: "-15px",
              overflowY: "scroll",
              maxHeight: "inherit",
            }}
            spacing={2}
          >
            <Features workspace={workspace} t={t} action={action} tour={tour} />
            <TinyError>
              <Tooltip title={t("tour")} placement="left">
                <Box>
                  <Tip title={t("tourTour")} name="tour" tour={tour.name} placement="left">
                    <Button variant="small-tab" onClick={startTour}>
                      <DynamicIcon icon={"fa-signs-post"} />
                    </Button>
                  </Tip>
                </Box>
              </Tooltip>
            </TinyError>
            {/* <TinyError>
              <Tooltip title={t("checklist")} placement="left">
                <Box>
                  <Tip title={t("tourChecklist")} name="cheklist" tour={tour.name} placement="left">
                    <Button variant="small-tab" onClick={toggleChecklist}>
                      <DynamicIcon icon={"fa-list-check"} />
                    </Button>
                  </Tip>
                </Box>
              </Tooltip>
            </TinyError> */}
          </Stack>
        </Stack>
        <Stack>
          {!meta.get("rightDesk") ? (
            <Box id="assist-button-portal"></Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                left: "10px",
                bottom: "10px",
                color: "#67232F",
                fontSize: "11px",
              }}
            >
              BETA v{version}
            </Box>
          )}
        </Stack>
      </Stack>
      <Checklist open={checklist} toggleChecklist={toggleChecklist} />
    </ComponentError>
  );
}
