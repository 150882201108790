/** @format */
import { Functional } from "unit";

const unit = Functional.unit("workspace/step");

export function stepActions(step) {
  unit.report({
    method: "stepActions",
    test: "Actions related to certain steps should execute as expected.",
  });
  /* eslint-disable no-alert, no-console */
  const { action, ui, errors, t } = this.props;
  const workspace = this;
  /* eslint-enable no-alert, no-console */
  try {
    step.forEach((a) => {
      eval(a, "~17");
    });
  } catch (error) {
    errors.warning(t("unexpectedError"), error, "~20");
  }
}

export function getStep(feature, step, from = "~24") {
  unit.report({
    method: "getStep",
    payload: { step: step, feature: feature, from: from },
  });

  if (!this?.state?.project?.features?.[feature]?.checklist[step]) return false;

  return this.state.project.features[feature].checklist[step];
}

export function setStep({ feature, step, note = null, state = true, performing = false }, from = "~35") {
  unit.report({
    method: "setStep",
    payload: { step: step, feature: feature, state: state },
    from: from,
    // Update the user in mixpanel
    event: "setPeople",
    test: "The step should be updated, progress logged and retained after a page load.",
  });
  let project = { ...this.state.project };

  if (!project.features[feature]) return false;

  project.features[feature].checklist[step] = state;

  this.update.project({
    project: project,
    performing: performing,
    from: "~53",
    note: note || "Updated " + feature + " checklist.",
    ai: null,
  });
}
