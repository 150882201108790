/** @format */
import SpreadTemplate from "@Workspace/Usable/Features/Designer/config/templates/Spread";
import TextTemplate from "@Workspace/Usable/Features/Designer/config/templates/Text";
import Units from "@Workspace/Usable/Features/Designer/config/units.json";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { nanoid } from "nanoid";
import { Functional } from "unit";

const unit = Functional.unit("Manuscript");

// convert mm to px
const convert = (px) => {
  return Number(((px * Units.metric.calc) / 10).toFixed(4));
};

export function reviewTransfer(from = "~19") {
  unit.report({
    method: "countSpreads",
    message: "Capturing from Manuscript.",
    test: "Confirm spreadcount calculates correctly",
    steps: ["From Manuscript", "Confirm spread count calculates correctly"],
    from: from,
  });

  if (!this.editor.loaded()) return {};

  const draft = cloneDeep(this.props.data.draft);
  const spreadbreak = nanoid();

  let response = { delimiter: spreadbreak, lines: 0, breaks: 0, spreads: 1, words: 0, expected: 0, node: [] };

  const extractContent = (node) => {
    let content = [];

    // If node is an array, recursively process each item.
    if (Array.isArray(node)) {
      node.forEach((child) => {
        content.push(...extractContent(child));
      });
      return content;
    }

    //
    if (node.text) {
      content.push(node.text);
      response.lines++;
      response.words += node.text.split(" ").length;
    }

    // If the node has a 'type' property equal to "break", process as needed.
    if (node.type === "break") {
      // You can add any specific processing logic for breaks here if needed.
      content.push(spreadbreak);
      response.breaks++;
      response.spreads++;
    }

    // If the node has children, recursively process them.
    if (node.children) {
      content.push(...extractContent(node.children));
    }

    return content;
  };

  const content = extractContent(draft);

  return { ...response, nodes: content };
}

export async function confirmTransfer(from = "~74") {
  unit.report({
    method: "confirmTransfer",
    message: "Confirm the transfer of the manuscript to the storyboard.",
    test: "Check if the wordcount matches what's on the screen.",
    from: from,
    steps: ["From Manuscript", "Transfer manuscript to storyboard"],
  });
  const { workspace, errors, t } = this.props;
  const token = workspace.token();
  try {
    let { data } = await axios.post("/api/workspace/usable/designer/trims/trim", {
      token: token,
      feature: "storyboard",
    });
    let range = data?.data?.range;
    let dimensions = data?.data?.dimensions;
    if (!range || !dimensions) throw "No range or dimensions.";
    return { spreads: Math.max(range[0] / 2), dimensions: [dimensions[0] * 2, dimensions[1]] };
  } catch (error) {
    errors.error(t("errrorTransferTrim"), error, "~94");
    return null;
  }
}

export function completeTransfer(spreads, scale, from = "~99") {
  unit.report({
    method: "completeTransfer",
    message: "Complete the transfer of the manuscript to the storyboard.",
    test: "Check if the wordcount matches what's on the screen.",
    from: from,
    steps: ["From Manuscript", "Transfer manuscript to storyboard"],
  });
  const { performing, errors, t, workspace } = this.props;
  return new Promise((resolve, reject) => {
    try {
      performing.set.updating("updating", "~110");

      if (!this.editor.loaded) throw "Editors not loaded.";

      if (spreads.length < 4) throw "Not enough spreads are being transferred.";

      // Get the data we need
      const language = workspace.language.get();
      const storyboard = workspace.data.feature("storyboard");

      const newSpreads = [];

      spreads.forEach((spread) => {
        if (spread.length == 0) {
          newSpreads.push(cloneDeep(SpreadTemplate));
        } else {
          const newSpread = cloneDeep(SpreadTemplate);
          spread.forEach((node) => {
            const clonedTextTemplate = cloneDeep(TextTemplate);
            newSpread.elements.push({
              ...clonedTextTemplate,
              id: nanoid(),
              style: [
                {
                  ...clonedTextTemplate.style[0],
                  width: convert(node.width / scale),
                  height: convert(node.height / scale),
                  left: convert(node.left / scale),
                  top: convert(node.top / scale),
                },
              ],
              font: { ...clonedTextTemplate.font, size: 32, family: storyboard.fonts[0] || "Acme" },
              text: { [language]: node.text },
            });
          });
          newSpreads.push(newSpread);
        }
      });

      workspace.update
        .feature(
          {
            feature: "storyboard",
            data: { ...storyboard, spreads: newSpreads },
            note: "Transferred manuscript to storyboard.",
            ai: 0,
          },
          "~157"
        )
        .then(() => {
          performing.set.updating("success", "~160");
          resolve(true);
        });
    } catch (error) {
      performing.set.updating("error", "~164");
      errors.error(t("errorTransferringManuscript"), error, "~165");
      reject(error);
    }
  });
}
