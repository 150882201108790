/** @format */

import merge from "lodash/merge";
import { Frame } from "scenejs";
import { Functional } from "unit";

const unit = Functional.unit("designer/style");

// Default values for the properties
const defaultTransform = {
  rotate: 0,
  scale: [1, 1],
  matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
};

export function getNormalizedStyle(style) {
  const isValidDegree = (value) => {
    const regex = /^-?\d+(\.\d+)?$/;
    return regex.test(value);
  };

  // Normalize transform properties for CSS
  if (style.transform) {
    const transformStrings = [];

    // Rotate needs to be a string with "deg"
    if (style.transform.rotate !== undefined) {
      if (!isValidDegree(style.transform.rotate.toString().replace("deg", ""))) {
        style.transform.rotate = 0;
      }

      if (!style.transform.rotate.toString().endsWith("deg")) {
        style.transform.rotate += "deg";
      }

      transformStrings.push(`rotate(${style.transform.rotate})`);
    }

    if (style.transform.matrix3d) {
      transformStrings.push(`matrix3d(${style.transform.matrix3d.join(",")})`);
    }

    if (style.transform.scale) {
      transformStrings.push(`scale(${style.transform.scale.join(",")})`);
    }

    style.transform = transformStrings.join(" ");
  }

  // Add "px" to width, height if they are valid numbers
  ["width", "height"].forEach((property) => {
    if (style[property] !== undefined) {
      const isPxNumber = typeof style[property] === "string" && style[property].endsWith("px");
      const isPlainNumber = typeof style[property] === "number";
      let pixelValue = isPlainNumber ? style[property] : parseFloat(style[property]);

      if (isPxNumber || isPlainNumber) {
        style[property] = pixelValue + "px";
      } else {
        style[property] = "auto";
      }
    }
  });

  // Add "px" to width, height, top, and left if they are valid numbers
  ["width", "height", "top", "left"].forEach((property) => {
    if (style[property] !== undefined) {
      const isPxNumber = typeof style[property] === "string" && style[property].endsWith("px");
      const isPlainNumber = typeof style[property] === "number";
      let pixelValue = isPlainNumber ? style[property] : parseFloat(style[property]);

      if (isPxNumber || isPlainNumber) {
        style[property] = pixelValue + "px";
      } else {
        style[property] = "auto";
      }
    }
  });

  return style;
}

export function getStructuredStyle(style) {
  let structuredStyle = { ...style };

  if (structuredStyle.transform) {
    // Rotate
    if (structuredStyle.transform.rotate !== undefined) {
      if (typeof structuredStyle.transform.rotate === "string") {
        structuredStyle.transform.rotate = parseFloat(structuredStyle.transform.rotate.replaceAll("deg", ""));
      }
    }
  }

  ["width", "height"].forEach((property) => {
    if (structuredStyle[property] !== undefined) {
      if (typeof structuredStyle[property] === "string") {
        const isPxNumber = structuredStyle[property].endsWith("px");
        const isAuto = structuredStyle[property] === "auto";
        if (isPxNumber) {
          let parsedValue = parseFloat(structuredStyle[property]);
          parsedValue = parsedValue < 40 ? 40 : parsedValue; // Ensure the value is not less than 40
          structuredStyle[property] = parsedValue;
        } else if (isAuto) {
          structuredStyle[property] = 40; // You might need to change this based on your requirements.
        } else {
          const parsed = parseFloat(structuredStyle[property]);
          structuredStyle[property] = isNaN(parsed) || parsed < 40 ? 40 : parsed; // default to 40 if parsing failed or less than 40
        }
      } else if (typeof structuredStyle[property] === "number") {
        structuredStyle[property] = structuredStyle[property] < 40 ? 40 : structuredStyle[property];
      }
    }
  });

  // Adjust 'top' and 'left' to ensure the elements are within the bounds
  ["top", "left"].forEach((property) => {
    if (structuredStyle[property] !== undefined) {
      if (typeof structuredStyle[property] === "string") {
        const isPxNumber = structuredStyle[property].endsWith("px");
        const isAuto = structuredStyle[property] === "auto";
        let pixelValue = parseFloat(structuredStyle[property]);

        if (isPxNumber || isAuto) {
          structuredStyle[property] = pixelValue;
        } else {
          const parsed = parseFloat(structuredStyle[property]);
          structuredStyle[property] = isNaN(parsed) ? pixelValue : parsed;
        }
      }
    }
  });

  return structuredStyle;
}

export function getStructuredTransform(transform, from = "~137") {
  const structuredTransform = {};

  // Split string into components
  const components = transform.match(/(\w+\([^\)]+\))/g);

  components.forEach((component) => {
    // Each transform component should start with its name, followed by parentheses
    const match = component.match(/^(\w+)\((.*?)\)$/);

    // A mismatch transform, we do nothing
    if (!match) return;

    const [, name, value] = match;

    switch (name) {
      case "rotate":
        structuredTransform.rotate = parseFloat(value.replaceAll("deg", ""));
        break;
      case "scale":
        structuredTransform.scale = value.split(",").map(Number);
        break;
      case "matrix3d":
        structuredTransform.matrix3d = value.split(",").map(Number);
        break;
      default:
        //console.error("Unknown transform component:", name);
        break;
    }
  });

  return structuredTransform;
}

export function validateStyle(data, spread, index, frame) {
  // Ensuring the necessary structure of data exists
  if (!data) {
    data = {};
  }
  if (!data.spreads) {
    data.spreads = [];
  }
  if (!data.spreads[spread]) {
    data.spreads[spread] = { elements: [] };
  }
  if (!data.spreads[spread].elements) {
    data.spreads[spread].elements = [];
  }
  if (!data.spreads[spread].elements[index]) {
    data.spreads[spread].elements[index] = { style: [] };
  }
  if (!data.spreads[spread].elements[index].style) {
    data.spreads[spread].elements[index].style = [];
  }

  let style = data.spreads[spread].elements[index].style[frame];

  if (!style) {
    data.spreads[spread].elements[index].style[frame] = {};
    style = data.spreads[spread].elements[index].style[frame];
  }

  if (!style.width) {
    style.width = 200;
  }

  if (!style.height) {
    style.width = 200;
  }

  let transform = style.transform;

  if (!transform) {
    style.transform = { ...defaultTransform };
    transform = style.transform;
  } else {
    if (transform.rotate === undefined) {
      transform.rotate = defaultTransform.rotate;
    }

    if (!transform.scale || !Array.isArray(transform.scale) || transform.scale.length !== 2) {
      transform.scale = defaultTransform.scale;
    }

    if (!transform.matrix3d || !Array.isArray(transform.matrix3d) || transform.matrix3d.length !== 16) {
      transform.matrix3d = defaultTransform.matrix3d;
    }
  }

  // Return updated data
  return data;
}

export function getElementStyle(props, from = "~230") {
  let { id, index, spread = this.state?.spread?.number || 0, frame = this.state.frame.number || 0 } = props;

  unit.report({
    method: "getElementStyle",
    from: from,
    test: "Elements created in the designer should be styled correctly.",
    message: "Getting the element style by " + id ? "id." : "index.",
    payload: props,
  });

  const exit = (_) => {
    return new Frame({}).toCSSObject();
  };

  try {
    const data = this.data("~246");

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return exit("Missing element or element style.", "~248");

    let style = getStructuredStyle.call(this, data?.spreads[spread]?.elements[index]?.style[frame], "~250");

    style.zIndex = 2500 + index;
    style.whiteSpace = "pre";

    // Deal with any images that might be off the spread (background, lowest layer)
    if (props.index === 0) {
      // Allowed margin: up to 90% of the element can be outside on all sides
      const allowedOutsideMargin = 0.9; // 10% inside

      // Deconstruct for clarity
      const { width: elementWidth, height: elementHeight, left: elementLeft, top: elementTop } = style;
      const { top: boundsTop, right: boundsRight, bottom: boundsBottom, left: boundsLeft } = this.state.bounds;

      // Calculate the visible portion of the element
      const visibleWidthLeft = Math.max(0, boundsLeft - elementLeft);
      const visibleWidthRight = Math.max(0, elementLeft + elementWidth - boundsRight);
      const visibleHeightTop = Math.max(0, boundsTop - elementTop);
      const visibleHeightBottom = Math.max(0, elementTop + elementHeight - boundsBottom);

      // Check if the element is outside the bounds
      const isOutsideToLeft = visibleWidthLeft >= elementWidth * allowedOutsideMargin;
      const isOutsideToRight = visibleWidthRight >= elementWidth * allowedOutsideMargin;
      const isOutsideToTop = visibleHeightTop >= elementHeight * allowedOutsideMargin;
      const isOutsideToBottom = visibleHeightBottom >= elementHeight * allowedOutsideMargin;

      // Reposition the element if it is outside the bounds
      if (isOutsideToLeft) {
        style.left = boundsLeft;
      }
      if (isOutsideToRight) {
        style.left = boundsRight - elementWidth;
      }
      if (isOutsideToTop) {
        style.top = boundsTop;
      }
      if (isOutsideToBottom) {
        style.top = boundsBottom - elementHeight;
      }
    }

    return getNormalizedStyle(style);
  } catch (error) {
    console.log(error);
    return exit(error, "~294");
  }
}

export function getElementRotation(props, from = "~298") {
  let { id, index, spread = this.state?.spread?.number || 0, frame = this.state.frame.number || 0 } = props;

  unit.report({
    method: "getElementRotation",
    from: from,
    test: "Get the element rotation to handle the controls.",
    message: "Getting the element style by " + id ? "id." : "index.",
    payload: props,
  });

  const exit = (_) => {
    return 0;
  };

  try {
    const data = this.data("~314");

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return exit("Missing element or element style.", "~316");

    return getStructuredStyle.call(this, data.spreads[spread].elements[index].style[frame], "~318").transform.rotate;
  } catch (error) {
    return exit(error, "~320");
  }
}

export function setElementStyle(props, from = "~324") {
  unit.report({
    method: "setElementStyle",
    from: from,
    payload: props,
    test: "Making any stylistic changes to elements are applied immediately locally/remotely.",
  });

  const { errors, t } = this.props;
  let { event = null, label, style } = props;

  const exit = (error = null) => {
    if (!error) return;
    console.error(error);
    return errors.error(t("errorStyleElement"), error, "~338");
  };

  if (style.width) event.target.style.width = style.width + "px";
  if (style.height) event.target.style.height = style.height + "px";
  if (style.top) event.target.style.top = style.top + "px";
  if (style.left) event.target.style.left = style.left + "px";

  if (!event) exit("Element missing.");

  try {
    const data = this.data("~349");
    const spread = this.state?.spread?.number || 0;
    const index = parseInt(event.target.getAttribute("data-index"));
    const frame = this.state?.frame?.number || 0;

    // 1. USING THIS TO REPLACE BELOW
    if (!data?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return;

    // 2. It might be more ideal to merge this into update project
    this.setState(
      (prevState) => {
        // Get the references from previous state
        let prevData = prevState.data;

        if (!prevData?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return prevState;

        let prevSpread = prevData.spreads[spread];
        let prevElement = prevSpread.elements[index];
        let prevStyle = prevElement.style[frame];

        // Merge existing transform properties with new properties from 'style'
        let updatedStyle = merge({}, prevStyle, style);

        let newData = {
          ...prevData,
          spreads: prevData.spreads.map((s, i) => {
            if (i !== spread) return s; // return original spread if not the one being updated
            return {
              ...s,
              elements: s.elements.map((e, j) => {
                if (j !== index) return e; // return original element if not the one being updated
                return {
                  ...e,
                  style: Array.isArray(e.style)
                    ? e.style.map((st, k) => {
                        if (k !== frame) return st; // return original style if not the one being updated
                        return {
                          ...st,
                          ...getStructuredStyle.call(this, updatedStyle, "~387"),
                        };
                      })
                    : [],
                };
              }),
            };
          }),
        };

        // Return new state
        return { data: validateStyle.call(this, newData, spread, index, frame) };
      },
      () => {
        this.debounceCreateUndo("~401");
        this.debounceUpdate(this.state.data, "~402");
      }
    );

    if (label) this.label.set(label);
  } catch (_) {}
}

// # TODO - turning off confirmation for the moment
export function resetElementStyle(confirm = true, from = "~411") {
  unit.report({
    method: "removeElementStyle",
    from: from,
    test: "When resetting an element it should return to it's proper dimensions and orientation.",
  });

  const { errors, t, workspace } = this.props;

  const exit = (error = null) => {
    if (!error) return;
    return errors.error(t("errorResetElement"), error, "~422");
  };

  if (!confirm) {
    workspace.confirm.open({
      message: "confirmResetStyle",
      confirm: () => resetElementStyle.call(this, true, "~428"),
    });
    return;
  }

  try {
    let data = this.data("~434");

    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected().index;
    const frame = this.state?.frame?.number || 0;

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) exit();

    data.spreads[spread].elements[index].style[frame].transform = {
      ...data.spreads[spread].elements[index].style[frame].transform,
      ...defaultTransform,
    };

    this.project
      .update(
        {
          data: data,
          note: "Reset element style.",
          ai: 0,
        },
        "~454"
      )
      .then(() => {
        this.element.refresh();
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    return exit(error);
  }
}

export function getElementResetable(from = "~467") {
  unit.report({
    method: "getElementResetable",
    from: from,
    test: "When checking if an element can be reset it should correctly identify non-default dimensions and orientations.",
  });

  try {
    const data = this.data("~475");
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected().index;
    const frame = this.state.frame.number || 0;

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return false;

    // Check if any of the transform properties are non-default
    const transform = getStructuredStyle.call(
      this,
      data.spreads[spread].elements[index].style[frame],
      "~486"
    ).transform;

    // Check if any properties don't match the default
    for (let property in defaultTransform) {
      if (JSON.stringify(transform[property]) !== JSON.stringify(defaultTransform[property])) {
        return true; // The element can be reset
      }
    }

    return false; // The element cannot be reset (it is already in default state)
  } catch (_) {
    return false;
  }
}

export function resetElementTransform(event) {
  try {
    // event.setFixedDirection([0, 0]);
    // event.setTransform(event.target.style.transform, 0);
  } catch (error) {
    console.error(error);
  }
}
