async function page(name) {
  // Get the last query props
  let lastQueryProps = this.queryStates[name];

  // Check if there's any query saved for the name
  if (!lastQueryProps) {
    console.error("No previous query found for the name:", name);
    return;
  }
  // Add the last visible document to the query props
  if (this.lastVisible[name]) {
    lastQueryProps.lastVisible = this.lastVisible[name];
  }

  // Run the query
  await this.query(lastQueryProps);
}

export { page };
