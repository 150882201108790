/**
 * Image
 *
 * @format
 */
import { nanoid } from "nanoid";
import { Frame } from "scenejs";
import { Functional } from "unit";
import ImageTemplate from "../../config/templates/image.json";

const unit = Functional.unit("Designer/Element/Image");

export function getImageStyle(element, from = "~13") {
  unit.report({ method: "getImageStyle", payload: element, from: from });

  const exit = () => {
    return new Frame({}).toCSSObject();
  };

  try {
    const data = this.data("~21");

    const spread = this.state?.spread?.number || 0;
    const index = element.index;
    const frame = this.state?.frame?.number || 0;

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return exit("Missing image.", "~27");

    const style = data?.spreads[spread]?.elements[index]?.style[frame];

    return new Frame({
      width: style?.width ? style.width + "px" : "100%",
      height: style?.height ? style.height + "px" : "100%",
      ...{
        backgroundImage: "url(" + element.url + ")",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      },
    }).toCSSObject();
  } catch (error) {
    console.error(error, "~42");
    return exit();
  }
}

export function getImageUrl(element, from = "~47") {
  unit.report({ method: "getImageUrl", payload: element, from: from });

  try {
    const data = this.data("~51");
    const spread = this.state?.spread?.number || 0;
    const index = element?.index || this.element.selected().index;

    return data?.spreads[spread]?.elements[index]?.url || null;
  } catch (error) {
    return null;
  }
}

export function getImageId(element, from = "~61") {
  unit.report({ method: "getImageId", payload: element, from: from });

  try {
    const data = this.data("~65");
    const spread = this.state?.spread?.number || 0;
    const index = element?.index || this.element.selected().index;

    return data?.spreads[spread]?.elements[index]?.id || null;
  } catch (error) {
    return null;
  }
}

export function getMediaId(element, from = "~75") {
  unit.report({ method: "getImageId", payload: element, from: from });

  try {
    const data = this.data("~79");
    const spread = this.state?.spread?.number || 0;
    const index = element?.index || this.element.selected().index;

    return data?.spreads[spread]?.elements[index]?.mediaId || null;
  } catch (error) {
    return null;
  }
}

// Adding

export function addImageFromElements(props, from = "~91") {
  unit.report({
    method: "addImageFromElements",
    from: from,
  });

  const { t, workspace, errors } = this.props;

  return new Promise(async (resolve, reject) => {
    try {
      const { id, top, left } = props;
      const { media, t } = this.props;
      const image = await media.document.get(id);

      if (!image) {
        errors.warn(t("unexpectedError"), "No matching image found.", "~106");
        return;
      }

      const { width: editorWidth, height: editorHeight } = this.editor.style();
      let width, height, imgTop, imgLeft;

      // For non-background images, set the width and height to a reasonable size
      const maxImageWidth = editorWidth * 0.5 * workspace.zoom.get();
      const maxImageHeight = editorHeight * 0.5 * workspace.zoom.get();
      const minImageWidth = editorWidth * 0.5 * workspace.zoom.get();
      const minImageHeight = editorHeight * 0.5 * workspace.zoom.get();

      // Calculate the aspect ratio of the image
      const imageAspectRatio = image.meta.width / image.meta.height;

      if (imageAspectRatio < 1) {
        // For portrait images, set the height to the maximum height and calculate the width based on the aspect ratio
        height = Math.min(maxImageHeight, Math.max(minImageHeight, image.meta.height));
        width = height * imageAspectRatio;
      } else {
        // For landscape or square images, set the width to the maximum width and calculate the height based on the aspect ratio
        width = Math.min(maxImageWidth, Math.max(minImageWidth, image.meta.width));
        height = width / imageAspectRatio;
      }

      // Center the image horizontally and position it based on the top mouse event
      imgTop = Math.max(50, Math.min(top - height / 2, editorHeight - height - 50));
      imgLeft = Math.max(50, Math.min(left - width / 2, editorWidth - width - 50));

      const described = workspace.languages.get().reduce((acc, language) => {
        acc[language.code] = null;
        return acc;
      }, {});

      // Get the compressed (clco color, compressed) version or url
      const url = image?.variations?.clco || image.url;

      const element = {
        ...ImageTemplate,
        id: nanoid(),
        mediaId: image.id,
        described: described,
        url: url,
        variation: "clco", // the color compressed version
        style: [
          {
            ...ImageTemplate.style[0],
            width: width * workspace.zoom.get(),
            height: height * workspace.zoom.get(),
            left: imgLeft,
            top: imgTop,
          },
        ],
      };

      this.element.add(element).then(() => {
        resolve();
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~166");
      reject(error);
    }
  });
}

export function addImageFromPaste(props, from = "~172") {
  unit.report({
    method: "addImageFromPaste",
    from: from,
  });

  const { t, workspace, errors } = this.props;

  return new Promise((resolve, reject) => {
    try {
      const { id, top, left, width, height, url } = props;
      const { width: editorWidth, height: editorHeight } = this.editor.style();

      let imgWidth, imgHeight, imgTop, imgLeft;

      // For non-background images, set the width and height to a reasonable size
      const maxImageWidth = editorWidth * 0.75;
      const maxImageHeight = editorHeight * 0.75;
      const minImageWidth = editorWidth * 1.25;
      const minImageHeight = editorHeight * 1.25;

      // Calculate the aspect ratio of the image
      const imageAspectRatio = width / height;

      if (imageAspectRatio < 1) {
        // For portrait images, set the height to the maximum height and calculate the width based on the aspect ratio
        imgHeight = Math.min(maxImageHeight, Math.max(minImageHeight, height));
        imgWidth = imgHeight * imageAspectRatio;
      } else {
        // For landscape or square images, set the width to the maximum width and calculate the height based on the aspect ratio
        imgWidth = Math.min(maxImageWidth, Math.max(minImageWidth, width));
        imgHeight = imgWidth / imageAspectRatio;
      }

      // Center the image horizontally and position it based on the top mouse event
      imgTop = Math.max(50, Math.min(top - height / 2, editorHeight - height - 50));
      imgLeft = Math.max(50, Math.min(left - width / 2, editorWidth - width - 50));

      const described = workspace.languages.get().reduce((acc, language) => {
        acc[language.code] = null;
        return acc;
      }, {});

      const element = {
        ...ImageTemplate,
        id: nanoid(),
        mediaId: id,
        described: described,
        url: url,
        style: [
          {
            ...ImageTemplate.style[0],
            width: imgWidth * workspace.zoom.get(),
            height: imgHeight * workspace.zoom.get(),
            left: imgLeft,
            top: imgTop,
          },
        ],
      };

      this.element.add(element).then(() => {
        resolve();
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~236");
      reject(error);
    }
  });
}

// Variations

export function setTransparentImage({ id, spread, url }, from = "~244") {
  unit.report({ method: "getImageUrl", payload: url, from: from });

  return new Promise((resolve, reject) => {
    try {
      const data = this.data("~249");

      if (!data?.spreads[spread]?.elements) throw "Elements are missing.";

      // Find the index of the element with the specified id
      const index = data.spreads[spread].elements.findIndex((el) => el.id === id);

      if (index === -1) throw "Element is missing.";

      // Set the image to the compressed transparent (cotr)
      data.spreads[spread].elements[index].url = url;
      data.spreads[spread].elements[index].variation = "cotr";

      this.project
        .update(
          {
            data: data,
            note: "Converted image to transparent.",
            ai: 0,
          },
          "~269"
        )
        .then(() => {
          resolve();
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error(error);
      return reject(error);
    }
  });
}

export async function getTransparentImage(element, from = "~284") {
  unit.report({ method: "getImageId", payload: element, from: from });

  try {
    const data = this.data("~288");
    const spread = this.state?.spread?.number || 0;
    const index = element?.index || this.element.selected().index;
    const { media } = this.props;
    const image = await media.get(data?.spreads[spread]?.elements[index]?.mediaId);

    return image?.processed?.tr?.complete == true ? image?.processed?.tr?.url : false;
  } catch (error) {
    return false;
  }
}

export function isTransparentImage(from = "~300") {
  unit.report({ method: "isTransparentImage", from: from });

  try {
    const data = this.data("~304");
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected().index;

    // Check if the image is transparent (compressed, transparent)
    return data?.spreads[spread]?.elements[index]?.variation === "cotr";
  } catch (_) {
    return false;
  }
}

export async function removeTransparentImage(from = "~315") {
  unit.report({ method: "removeTransparentImage", from: from });
  try {
    const { media } = this.props;

    const data = this.data("~320");
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected().index;

    const mediaId = data?.spreads[spread]?.elements[index]?.mediaId;

    const image = await media.get(mediaId);

    if (!image?.url) return;

    data.spreads[spread].elements[index].url = image.url;
    data.spreads[spread].elements[index].variation = "clco";

    this.project.update(
      {
        data: data,
        note: "Converted image to transparent.",
        ai: 0,
      },
      "~339"
    );
  } catch (e) {
    console.error(e);
    return false;
  }
}
